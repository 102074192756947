
import './css/App.css';
import './css/ImageSlider.css';
import _Router from './component/Router';

function App() {
  return (

    <>
      <_Router />
    </>

  );
}

export default App;
